import React from 'react';
import { at } from 'lodash';
import { useField } from 'formik';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Switch
} from '@material-ui/core';
import useStyles from './styles';


export default function CheckboxField(props) {
  const { label, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const { setValue } = helper;
  const classes = useStyles();

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  function _onChange(e) {
    setValue(e.target.checked);
  }

  return (
    <>
     <FormControl {...rest} className={classes.toggleText}>
      <FormControlLabel
        value={field.value}
        checked={field.value}
        control={<>{field.value?<span className='text selected'>Yes</span>:<span className='text'>No</span>}<Switch {...field} onChange={_onChange} checked={field.value}/></>}
        label={label}
        labelPlacement={props.labelPlacement}
      />
      {_renderHelperText()}
    </FormControl> 
    </>
  );
}
