export default {
  formId: 'eoiForm',
  formField: {
    salutation: {
      name: 'salutation',
      label: '1. Salutation'
    },
    firstName: {
      name: 'firstName',
      label: '2. First Name*',
      requiredErrorMsg: 'First Name is required'
    },
    lastName: {
      name: 'lastName',
      label: '3. Last Name*',
      requiredErrorMsg: 'Last Name is required'
    },
   
    jobTitle: {
      name: 'jobTitle',
      label: '4. Job Title*',
      requiredErrorMsg: 'Job Title is required'
    },
    email: {
      name: 'email',
      label: '5. Email Address*',
      requiredErrorMsg: 'Email Address is required'
    },
    confirmEmail: {
      name: 'confirmEmail',
      label: 'Confirm Email Address*',
      requiredErrorMsg: 'Confirm Email Address is required'
    },
    phone: {
      name: 'phone',
      label: '6. Phone Number*',
      requiredErrorMsg: 'Phone Number is required'
    },
    orgType: {
      name: 'orgType',
      label: '7. Organisation Type*',
      requiredErrorMsg: 'Organisation Type is required'
    },
    orgName: {
      name: 'orgName',
      label: '8. Organisation Name*',
      requiredErrorMsg: 'Organisation Name is required'
    },
   
    organisationIDNumber: {
      name: 'organisationIDNumber',
      label: '9. Registered Charity/Company Number',
      requiredErrorMsg: 'Registered Charity/Company Number is required'
    },
    address: {
      name: 'address',
      label: '10. Address*',
      requiredErrorMsg: 'Address is required'
    },
    postcode: {
      name: 'postcode',
      label: '11. Postcode*',
      requiredErrorMsg: 'Postcode is required'
    },
    website: {
      name: 'website',
      label: '12. Website',
     /*  requiredErrorMsg: 'Website is required' */
    },
    
  
   /*  regCharityNumber: {
      name: 'regCharityNumber',
      label: '12. Registered Charity Number',
      requiredErrorMsg: 'Registered Charity Number is required'
    },
    regCompanyNumber: {
      name: 'regCompanyNumber',
      label: '13. Registered Company Number',
      requiredErrorMsg: 'Registered Company Number is required'
    }, */
    lastFYTurnover: {
      name: 'lastFYTurnover',
      label: '13. Organisational Turnover for the last financial year (£)*',
      requiredErrorMsg: 'Organisational Turnover.. is required'
    },
    orgAim: {
      name: 'orgAim',
      label: '14. Please tell us about your organisation and how your work aligns with the Foundation’s areas of interest*',
      requiredErrorMsg: 'Please tell us about your organisation.. is required'
    },
    challenges: {
      name: 'challenges',
      label: '15. What do you see as your biggest challenge to delivering your charitable objectives in the next 6-12 months?*',
      requiredErrorMsg: 'What do you see as your biggest challenge.. is required'
    },
    workPriorities: {
      name: 'workPriorities',
      label: '16. Which of the following priorities does your main work align to?*',
      requiredErrorMsg: 'Which of the following priorities.. is required'
    }
  }
};
