import React, {useContext} from 'react';
import { useFormikContext } from 'formik';
import { Typography, Grid } from '@material-ui/core';
import AccordionField from '../fields/AccordionField';
import { EligibilityContext } from '../common/EligibilityContext';

import useStyles from './styles';

export default function Review(props) {
  const classes = useStyles();

  const {eligibilityResult} = useContext(EligibilityContext);
  const { values: formValues } = useFormikContext();
  const workPriorities = eligibilityResult.values.workPriorities && <ul> {eligibilityResult.values.workPriorities.sort().map((item)=><li>{item}</li>)}</ul>;
  const location = eligibilityResult.values.location && <ul> {eligibilityResult.values.location.sort().map((item)=><li>{item}</li>)}</ul>;
  const formatCurrency = (value) => {
    return `£${parseFloat(value).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
  }
  const steps = [
                  {title:"Personal details", 
                    values:{"1. Saluation": formValues.salutation, 
                            "2. First Name":formValues.firstName, 
                            "3. Last Name":formValues.lastName, 
                            "4. Job Title":formValues.jobTitle, 
                            "5. Email Address":formValues.email, 
                            "6. Phone Number":formValues.phone}},
                  {title:"Organisation details", 
                    values:{
                            "7. Organisation Type": formValues.orgType, 
                            "8. Organisation Name":formValues.orgName, 
                            "9. Registered Charity/Company Number": formValues.organisationIDNumber, 
                            "10. Address":formValues.address, 
                            "11. Postcode":formValues.postcode, 
                            "12. Website":formValues.website,
                            "13. Organisational Turnover for the last financial year (£)": formatCurrency(formValues.lastFYTurnover), 
                            "14. Please tell us about your organisation and how your work aligns with the Foundation’s areas of interest":formValues.orgAim, 
                            "15. What do you see as your biggest challenge to delivering your charitable objectives in the next 6-12 months?":formValues.challenges,
                            "16. Are you a registered charity or community interest company (CIC)?":eligibilityResult.values.regCharity?"Yes":"No",
                            "17. Please select the areas you operate in": location,
                            "18. Which of the following priorities does your main work align to?":workPriorities}
                  }
                ]
  const disclaimerText = props.init && props.init.formTexts.DisclaimerText;

  return (
    <React.Fragment>
      <div className={classes.review}>
        <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary" gutterBottom>
        Review
        </Typography>
        <Grid container>
          <AccordionField steps={steps} onEdit={props.onEdit}/>
              <div dangerouslySetInnerHTML={{__html:disclaimerText}}></div>
        </Grid>
      </div>
    </React.Fragment>
  );
}
