import React from 'react';
import { Grid, Typography, Button, IconButton } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ListItem from '@material-ui/core/ListItem';
import useStyles from './styles';
import EditIcon from '@material-ui/icons/Edit';

const modules = {
  toolbar: null
};

function getStep(step, classes) {
    let {values} = step;
    return  <List>
                {
                    Object.keys(values).map((key,index)=> {
                        if(key.indexOf('14.') > -1 || key.indexOf('15.') > -1) {
                               return <div className={classes.reviewRichText}><ListItemText primary={key}/><ReactQuill theme="snow" readOnly={true} value={values[key]} modules={modules}/></div>
                        }else {
                            return <ListItem button><ListItemText primary={key} secondary={values[key]}/></ListItem>
                        }
                    })
                }
            </List>
}

export default function AccordionField(props) {
  const classes = useStyles();
  const {steps} = props;
  const onEdit = (step) => {
    if(props.onEdit) {
      props.onEdit(step);
    }
   }
    return (
        <div className={classes.root}>
        {steps.length > 0 && steps.map((step, index)=>
            <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
            <Typography component="h1" variant="h6" className={classes.subTitle}>{step.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                  {getStep(step, classes)}
                  <IconButton aria-label="edit" onClick={()=>onEdit(index)} className={classes.buttonEdit}><EditIcon /></IconButton>
              </Typography>
            </AccordionDetails>
          </Accordion>
        )}  
      </div>
    )
}
