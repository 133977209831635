import eligibilityCheckFormModel from './eligibilityCheckFormModel';
const {
  formField: {
    regCharity,
    location,
    workPriorities,
  }
} = eligibilityCheckFormModel;

 export default {
  [regCharity.name]:false,
  [location.name]:[],
  [workPriorities.name]: [],
}; 
