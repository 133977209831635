export default {
  formId: 'eligibilityCheckForm',
  formField: {
    regCharity: {
      name: 'regCharity',
      label: 'Are you a registered charity or community interest company (CIC)?'
    },
    location: {
      name: 'location',
      label: 'Please select the areas you operate in*',
      requiredErrorMsg: 'Please select the areas you operate in is required'
    },
    workPriorities: {
      name: 'workPriorities',
      label: 'Which of the following priorities does your main work align to?*',
      requiredErrorMsg: 'Which of the following priorities.. is required'
    }
  }
};
