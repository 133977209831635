import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { at } from 'lodash';
import { useField } from 'formik';
import {  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText } from '@material-ui/core';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    quill: {
      marginTop:'1.4em'
    },
    quillExt: {
      marginTop:'3em',
    },
    label: {
      fontSize: '1.4em',
      width: 'max-content',
      color:theme.palette.secondary.main,
    },
    labelMulti: {
      fontSize: '1.4em',
      width: 'auto',
      lineHeight:'1.6em',
      color:theme.palette.secondary.main,
    },
   
    quillControl: {
      'border':'1px solid #ccc',
      '& label': {
        transform: 'translate(8px, -6px) scale(0.75)',
        backgroundColor:'#fff',
        paddingLeft:5,
        paddingRight:5
      },
      '& .ql-toolbar': {
        'border':'0px solid #ccc',
        'border-bottom':'1px solid #ccc',

      },
      '& .ql-container': {
        'border':'0px solid #ccc',
        fontSize: 14,
      },
      '& .orgCommitmentLearning': {
        marginTop:'7.6em'
      },
      '& .orgGovernanceArrangements': {
        marginTop:'3.6em'
      },
      '& .orgLinkStrategicPlans': {
        marginTop:'1.6em'
      },
    },

    quillControlMulti: {
      'border':'1px solid #ccc',
      '& label': {
        transform: 'translate(8px, -6px) scale(0.75)',
        backgroundColor:'#fff',
        paddingLeft:5,
        paddingRight:5
      },
      '& .ql-toolbar': {
        'border':'0px solid #ccc',
        'border-bottom':'1px solid #ccc',

      },
      '& .ql-container': {
        'border':'0px solid #ccc',
        marginTop:'2.6em'
      },

    },
  
  }),
);

export default function InputRichTextField(props) {
  const classes = useStyles();
  const { label, errorText, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const [touched, error] = at(meta, 'touched', 'error');
  //const isError = touched && error && true;
  const { setValue } = helper;
  const modules = {
    toolbar: null
  };
  function _renderHelperText() {
    if (touched && error) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  function _onChange(data) {
    setValue(data);
  }

  return (
      <FormControl {...rest} error={touched && error} className={classes.quillControl}>
        <InputLabel className={props.labelClass ? classes.labelMulti : classes.label}>{label}</InputLabel>
          <ReactQuill 
            className={props.quillClass ? props.quillClass :classes.quill}
            theme="snow"
            modules={modules}
            {...field}
            value={field.value? field.value:''}
            onChange={_onChange}
            onBlur= {()=>{}}
            {...rest}/>
          {_renderHelperText()}
      </FormControl>
  );
}
