import * as Yup from 'yup';
import moment from 'moment';
import eoiFormModel from './eoiFormModel';
const {
  formField: {
    firstName,
    lastName,
    jobTitle,
    email,
    confirmEmail,
    phone,
    orgName,
    address,
    postcode,
    website,
    organisationIDNumber,
    /* regCharityNumber,
    regCompanyNumber, */
    orgType,
    lastFYTurnover,
    orgAim,
    challenges,
 /*    workPriorities, */
  }
} = eoiFormModel;

const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const postcodeRegExp =  /^[a-zA-Z]{1,2}([0-9]{1,2}|[0-9][a-zA-Z])\s*[0-9][a-zA-Z]{2}$/;
const urlRegExp = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
const currencyRegExp = /^\$?[0-9][0-9,]*[0-9]\.?[0-9]{0,2}$/i;
const regCharityOrCompanyNumberRegExp = /^[0-9][0-9,]*[0-9]\.?[0-9]{0,2}$/i;
const regCharityOrCompanyNumberMin = 5;
const regCharityOrCompanyNumberMax = 10;

export default [
  Yup.object().shape({
    [firstName.name]: Yup.string()
    .required(`${firstName.requiredErrorMsg}`)
    .max(255, `${firstName.label} exceeded limited charcters!`),
    [lastName.name]: Yup.string()
      .max(255, `${lastName.label} exceeded limited charcters!`)
      .required(`${lastName.requiredErrorMsg}`),
    [jobTitle.name]: Yup.string()
      .required(`${jobTitle.requiredErrorMsg}`)
      .max(255, `${jobTitle.label} exceeded limited charcters!`),
    [email.name]: Yup.string()
      .required(`${email.requiredErrorMsg}`)
      .email('Invalid Email'),
    [confirmEmail.name]: Yup.string()
      .required(`${confirmEmail.requiredErrorMsg}`)
      .when("email", {
        is: email => (email && email.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref("email")], `${email.label} doesn't match!`)
      }),
    [phone.name]: Yup.string()
      .required(`${phone.requiredErrorMsg}`)
       .matches(phoneRegExp, `Invalid ${phone.label}!`) 
      .test('len', `Invalid ${phone.label}!`, val => val && val.length === 11)
  }),
  Yup.object().shape({
    [orgName.name]: Yup.string()
      .required(`${orgName.requiredErrorMsg}`)
      .max(255, `${orgName.label} exceeded limited charcters!`),
    [address.name]: Yup.string().required(`${address.requiredErrorMsg}`),
    [postcode.name]: Yup.string()
      .required(`${postcode.requiredErrorMsg}`)
      .matches(postcodeRegExp, `Invalid ${postcode.label}`),
    [website.name]: Yup.string()
     /*  .required(`${website.requiredErrorMsg}`) */
     .nullable()
      .matches(urlRegExp, `${website.label} must start with either http or https`),
    /* [regCharityNumber.name]: Yup.string() */
    [organisationIDNumber.name]: Yup.string()
    .nullable()
    .min(regCharityOrCompanyNumberMin, `${organisationIDNumber.label} must be at least ${regCharityOrCompanyNumberMin} numbers!`)
    .max(regCharityOrCompanyNumberMax, `${organisationIDNumber.label} exceeded ${regCharityOrCompanyNumberMax} numbers!`)
    .matches(regCharityOrCompanyNumberRegExp, {message: `Invalid ${organisationIDNumber.label}`, excludeEmptyString: true}),
    /* [regCompanyNumber.name]: Yup.string()
    .nullable()
    .min(regCharityOrCompanyNumberMin, `${regCompanyNumber.label} must be at least ${regCharityOrCompanyNumberMin} numbers!`)
    .max(regCharityOrCompanyNumberMax, `${regCompanyNumber.label} exceeded ${regCharityOrCompanyNumberMax} numbers!`)
    .matches(regCharityOrCompanyNumberRegExp, {message: `Invalid ${regCompanyNumber.label}`, excludeEmptyString: true}), */
    [orgType.name]: Yup.string().required(`${orgType.requiredErrorMsg}`),
    [lastFYTurnover.name]: Yup.string()
      .required(`${lastFYTurnover.requiredErrorMsg}`)
      .matches(currencyRegExp, `Invalid ${lastFYTurnover.label}!`),
    [orgAim.name]: Yup.string()
      .required(`${orgAim.requiredErrorMsg}`)
      .test('len', `${orgAim.requiredErrorMsg}`, val => {
        if(val && val.indexOf('<p><br></p>')) {
          return true;
        }
      } ),
    [challenges.name]: Yup.string()
      .required(`${challenges.requiredErrorMsg}`)
      .test('len', `${challenges.requiredErrorMsg}`, val => {
        if(val && val.indexOf('<p><br></p>')) {
          return true;
        }
      } ),
    /* [workPriorities.name]: Yup.array().min(1, `${workPriorities.requiredErrorMsg}`), */
  })
 /*   Yup.object().shape({
   /*[address1.name]: Yup.string().required(`${address1.requiredErrorMsg}`),
     [city.name]: Yup.string()
      .nullable()
      .required(`${city.requiredErrorMsg}`),
    [zipcode.name]: Yup.string()
      .required(`${zipcode.requiredErrorMsg}`)
      .test(
        'len',
        `${zipcode.invalidErrorMsg}`,
        val => val && val.length === 5
      ),
    [country.name]: Yup.string()
      .nullable()
      .required(`${country.requiredErrorMsg}`)  
    [nameOnCard.name]: Yup.string().required(`${nameOnCard.requiredErrorMsg}`),
    [cardNumber.name]: Yup.string()
      .required(`${cardNumber.requiredErrorMsg}`)
      .matches(visaRegEx, cardNumber.invalidErrorMsg),
    [cvv.name]: Yup.string()
      .required(`${cvv.requiredErrorMsg}`)
      .test('len', `${cvv.invalidErrorMsg}`, val => val && val.length === 3)
  })  */
];
