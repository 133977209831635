import {
  createMuiTheme,
  responsiveFontSizes,
  makeStyles
} from '@material-ui/core/styles';
import { cyan, green, lightGreen, white } from '@material-ui/core/colors';

let theme = createMuiTheme({
  palette: {
    type: 'light',
     primary: {
       main:'#ce0d28'
     },
    secondary: {
     /*  main:'#ce0d28' */
      main:'#8aaf45'
    } 
  },
  typography: {
    //fontFamily: "Rockwell-MT"
  },
  shape: {
    borderRadius:1
  }
});

theme = responsiveFontSizes(theme);

const useStyle = makeStyles(() => ({
  root: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(960 + theme.spacing(2) * 2)]: {
      width: 960,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    '& .MuiStepIcon-text': {
      'fill': '#fff'
    }
  },
  title: {
    flexGrow: 1,
    fontFamily:'Rockwell-MT',
    '&:after': {
      content:' ',
      borderBottom:14,
      backgroundColor: theme.palette.primary.main,
      width:100
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  },
  overlayContainer: {
    backgroundColor:theme.palette.primary.main,
    height: 84
  }
}));

export { theme, useStyle };
