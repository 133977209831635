import eoiFormModel from './eoiFormModel';
const {
  formField: {
    salutation,
    firstName,
    lastName,
    jobTitle,
    email,
    confirmEmail,
    phone,
    orgName,
    address,
    postcode,
    website,
    organisationIDNumber,
   /* regCharityNumber, 
     regCompanyNumber, */
    orgType,
    lastFYTurnover,
    orgAim,
    challenges,
    /* workPriorities, */
  }
} = eoiFormModel;
/*
 export default {
  [salutation.name]:'Mr',
  [firstName.name]: 'P',
  [lastName.name]: 'U',
  [jobTitle.name]: 'R',
  [email.name]: 'U@u.com',
  [confirmEmail.name]: 'U@u.com',
  [phone.name]: '07707999745',
  [orgName.name]: 'O',
  [address.name]: 'T',
  [postcode.name]: 'TW3 5KL',
  [website.name]: 'https://www.bg.co.uk',
  [organisationIDNumber.name]: '123456',
  [orgType.name]: 'Social Enterprise',
  [lastFYTurnover.name]: '34567',
  [orgAim.name]: 'PURU',
  [challenges.name]: 'SHOTAM',
}; 
*/


export default {
  [salutation.name]:'',
  [firstName.name]: '',
  [lastName.name]: '',
  [jobTitle.name]: '',
  [email.name]: '',
  [confirmEmail.name]: '',
  [phone.name]: '',
  [orgName.name]: '',
  [address.name]: '',
  [postcode.name]: '',
  [website.name]: '',
  [organisationIDNumber.name]: '',
  [orgType.name]: '',
  [lastFYTurnover.name]: '',
  [orgAim.name]: '',
  [challenges.name]: '',
};
