import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import {TEXT_NONE_OF_THE_ABOVE} from '../common/DataTypes';
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Checkbox,
  Box,
  Chip
} from '@material-ui/core';
import { EligibilityContext } from '../common/EligibilityContext';
import useStyles from './styles';


function SelectMultipleField2(props) {
  const classes = useStyles();
  const {eligibilityResult} = useContext(EligibilityContext);
  const [selectItem, setSelectItem] = React.useState([]);

  const { label, data, ...rest} = props;
  const [field, meta, includeNone] = useField(props);
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;
  const data3 = data && data.map(item=>{ return {label:item.Title, value:item.id}});
  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  const handleChange = (event) => {
    let selectedValue = event.target.value;
    if(selectedValue.map(item=>item.label).indexOf(TEXT_NONE_OF_THE_ABOVE) > -1) {
      if(field.value.map(item=>item.label).indexOf(TEXT_NONE_OF_THE_ABOVE) > -1) {
        event.target.value = selectedValue.filter((item)=>item.label.indexOf(TEXT_NONE_OF_THE_ABOVE) < 0);
      } else {
        event.target.value = selectedValue.filter((item)=>item.label.indexOf(TEXT_NONE_OF_THE_ABOVE) > -1);
      }

      field.onChange(event);
    } else {
      field.onChange(event);
    }
  };

  return (
    <FormControl {...rest} error={isError} className={classes.multiSelect}>
      <InputLabel>{label}</InputLabel>
      <Select {...field} multiple
        style={{fontSize:14}} 
        autoComplete="off"
        renderValue={(selected) => (selected.map(item=>item.label)).join(', ')}
        onChange={handleChange}>
          {data3.map((item, index) => (
            <MenuItem key={index} value={item} style={{fontSize:14}}>
              <Checkbox checked={selectedValue && selectedValue.map(sItem=>sItem.label).indexOf(item.label) > -1} />
              {item.label}
            </MenuItem>
          ))}
      </Select>
      {_renderHelperText()}
    </FormControl>
  );
}

SelectMultipleField2.defaultProps = {
  data: []
};

SelectMultipleField2.propTypes = {
  data: PropTypes.array.isRequired
};

export default SelectMultipleField2;
