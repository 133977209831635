import React, { useState } from 'react';
import {
    Button,
    Typography,
    CircularProgress,
    Grid
} from '@material-ui/core';
import { FormControlLabel, Switch } from '@material-ui/core';
import { SelectMultipleField, SelectMultipleField2, CheckboxField } from '../fields';
import useStyles from './styles';
import { Formik, Form } from 'formik';
import { TEXT_NONE_OF_THE_ABOVE } from '../common/DataTypes';


import eligibilityCheckValidationSchema from '../models/eligibilityCheckValidationSchema';
import eligibilityCheckFormModel from '../models/eligibilityCheckFormModel';
import eligibilityCheckFormInitialValues from '../models/eligibilityCheckFormInitialValues';

const { formId, formField } = eligibilityCheckFormModel;

function EligibilityCheck(props) {
    const classes = useStyles();
    const currentValidationSchema = eligibilityCheckValidationSchema[0];
    const [regCharity, setRegCharity] = React.useState(false);

    async function _submitForm(values, actions) {
        actions.setSubmitting(false);
        let isEligible = values.regCharity == true && values.location.indexOf(TEXT_NONE_OF_THE_ABOVE) == -1 && values.workPriorities.indexOf(TEXT_NONE_OF_THE_ABOVE) == -1;
        const locationIds = [];

        if (isEligible) {
            values.location.map(l => {
                const locationItem = props.init.locations.filter(item => item.Title == l)[0];
                locationIds.push(locationItem.id);
            })
        }
        let result = { passed: isEligible, checked: true, config: props.config, init: props.init, values: { regCharity: values.regCharity, location: values.location, locationIds:locationIds, charitableWorkArea: values.charitableWorkArea, workPriorities: values.workPriorities } };
        actions.setSubmitting(false);
        props.handleEligibility(result);
        actions.setSubmitting(false);
    }

    function _handleSubmit(values, actions) {
        _submitForm(values, actions);
    }

    function handleRegCharity(e) {
        setRegCharity(e.target.checked);
    }

    const getChoices = (name) => {
        let { fields } = props.init;
        let field = fields && fields.value?.filter((f) => f['name'] == name)[0];
        let choices = field && field['choice']['choices'] ? field['choice']['choices'] : [];
        return choices;
    }

    const locationChoices = props.init && [...props.init.locations, { Title: TEXT_NONE_OF_THE_ABOVE, id: '-1' }];
    let workPrioritiesChoices = props.init && getChoices("BGWorkPriorities") && getChoices("BGWorkPriorities");
    workPrioritiesChoices = props.init && workPrioritiesChoices && workPrioritiesChoices.filter(item =>
        item.indexOf("A Safe Place to Call Home - Ensuring young people have secure, stable accommodation") == -1);
    workPrioritiesChoices = props.init && workPrioritiesChoices && workPrioritiesChoices.filter(item =>
        item.indexOf("Access to Employment - Enabling young people to overcome barriers to work and kick-start their careers") == -1);
    workPrioritiesChoices = props.init && workPrioritiesChoices && workPrioritiesChoices.filter(item =>
        item.indexOf("Health and Wellbeing - Supporting young people to live happy healthy lives") == -1);
    const introText = props.init && props.init.formTexts.IntroductionText;
    const eligCriteriaText = props.init && props.init.formTexts.EligiblityCriteriaText;
    return (
        <React.Fragment>
            <div className="eligibilityCheck">
                <Formik
                    initialValues={eligibilityCheckFormInitialValues}
                    validationSchema={currentValidationSchema}
                    onSubmit={_handleSubmit}
                >
                    {({ isSubmitting }) => (
                        <Form id={formId}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <div dangerouslySetInnerHTML={{ __html: introText }}></div>
                                    <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary" gutterBottom>
                                        Eligibility Criteria
                                    </Typography>
                                    <div dangerouslySetInnerHTML={{ __html: eligCriteriaText }}></div>
                                </Grid>
                                <Grid item xs={12} style={{ marginBottom: 0, paddingBottom: 0 }}>
                                    <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary">
                                        Eligibility Check
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} style={{ paddingTop: 0 }}>
                                    <CheckboxField className={classes.toggleText} name={formField.regCharity.name} label={formField.regCharity.label} fullWidth variant="outlined" color="secondary" labelPlacement="start" />
                                </Grid>
                                <Grid item xs={12}>
                                    <SelectMultipleField
                                        name={formField.location.name}
                                        label={formField.location.label}
                                        data={locationChoices.map(item => item.Title)}
                                        value={formField.location.value}
                                        fullWidth
                                        variant="outlined"
                                        color="secondary"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SelectMultipleField
                                        name={formField.workPriorities.name}
                                        label={formField.workPriorities.label}
                                        data={workPrioritiesChoices}
                                        value={formField.workPriorities.value}
                                        fullWidth
                                        includeNone={true}
                                        variant="outlined"
                                        color="secondary"
                                    />

                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.wrapper}>
                                        <div className={classes.buttons}>
                                            <Button
                                                disabled={isSubmitting}
                                                type="submit"
                                                variant="contained"
                                                color="secondary"
                                                className={classes.buttonNext}
                                            >Next</Button>
                                            {isSubmitting && (
                                                <CircularProgress
                                                    size={24}
                                                    className={classes.buttonProgress}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </div>
        </React.Fragment>
    );
}

export default EligibilityCheck
