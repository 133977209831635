import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { InputField, SelectField ,InputRichTextField, InputAdornmentField} from '../fields';
import useStyles from './styles';
import { SelectType } from '../common/DataTypes';

export default function OrganisationDetails(props) {
  const classes = useStyles();
  
  const {
    formField: { orgName, address, postcode, website, regCharityNumber, regCompanyNumber,organisationIDNumber, orgType, lastFYTurnover, orgAim, challenges}, getChoices
  } = props;

  const orgTypesChoices = ["Charity","CIC"]; //getChoices("BGOrganisationType") && getChoices("BGOrganisationType");

  return (
    <React.Fragment>
      <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary" gutterBottom>
      Organisation details
        </Typography>
      <Grid container spacing={3}>
       
          <Grid item xs={12} className="selectRadio" sm={6}>
          <SelectField
            name={orgType.name}
            label={orgType.label}
            data={orgTypesChoices}
            fullWidth
            color="secondary"
            selectType={SelectType.RADIO}
            
          />
        </Grid>
        <Grid item xs={12} sm={6}>
            <InputField name={orgName.name} label={orgName.label} fullWidth variant="outlined" color="secondary"/>
          </Grid>
          <Grid item xs={12} sm={6}>
          <InputField
            name={organisationIDNumber.name}
            label={organisationIDNumber.label}
            fullWidth
            type="number"
            color="secondary" variant="outlined"/>
        </Grid>
          <Grid item xs={12} sm={6}>
            <InputField name={address.name} label={address.label} fullWidth variant="outlined" color="secondary"/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField name={postcode.name} label={postcode.label} fullWidth variant="outlined" color="secondary"/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField name={website.name} label={website.label} fullWidth variant="outlined" color="secondary"/>
          </Grid>
          <Grid item xs={12}>
          <InputAdornmentField
            name={lastFYTurnover.name}
            label={lastFYTurnover.label}
            adornment="£"
            fullWidth
            type="number"
            color="secondary" variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <InputRichTextField
            name={orgAim.name}
            label={orgAim.label}
            fullWidth
            color="secondary" variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <InputRichTextField
            name={challenges.name}
            label={challenges.label}
            fullWidth
            color="secondary" variant="outlined"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
