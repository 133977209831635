import React, { useState , useRef, useContext} from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  CircularProgress,
  Grid
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import { EligibilityContext } from '../common/EligibilityContext';

import useStyles from './styles';


import validationSchema from '../models/validationSchema';
import eoiFormModel from '../models/eoiFormModel';
import formInitialValues from '../models/formInitialValues';
import PersonalDetails from './PersonalDetails';
import OrganisationDetails from './OrganisationDetails';
import Review from './Review';
import Success from './Success';
import Errors from './Errors';

import ReCAPTCHA from "react-google-recaptcha";

const steps = ['Personal details', 'Organisation details', 'Review and Submit'];
const { formId, formField } = eoiFormModel;

function renderStepContent(step, props) {
    switch (step) {
      case 0:
        return <PersonalDetails formField={formField} {...props}/>;
      case 1:
        return <OrganisationDetails formField={formField} {...props}/>;
      case 2:
        return <Review {...props}/>;
      default:
        return <div>Not Found</div>;
  }
}

export default function EoiForm(props) {
  const {eligibilityResult} = useContext(EligibilityContext);
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const recaptchaRef = useRef();
  const config = eligibilityResult.config;

  const [serverErrors, setServerErrors] = useState([]);


  const getChoices = (name) => {
    let {fields} = eligibilityResult.init;
    let field =  fields && fields.value?.filter((f)=>f['name']== name)[0];
    let choices = field && field['choice']['choices']?field['choice']['choices']:[];
    return choices;
  }

  async function submitForm(values, actions){ 
    if(recaptchaRef.current) {
      actions.setSubmitting(true);
      setServerErrors([]);
      const token = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();

       const postData = {...values, initData:eligibilityResult.init, token, isRegCharity:eligibilityResult.values.regCharity,location:eligibilityResult.values.locationIds, workPriorities:eligibilityResult.values.workPriorities};
      let apiUrl= config.ApiUrl;// process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? process.env.REACT_APP_API_URL:'/api'; 
      const response = await fetch(`${apiUrl}/new`,
                      {
                        body: JSON.stringify(postData),
                        method: "POST",
                        cache:"no-cache",
                        headers: {
                            "Content-Type": "application/json; charset=utf-8",
                            "Authorization":`Bearer ${config.ApiToken}`
                        }
                    });
          const result = await response.json();
          console.log('result', result); 
          if(result) {
            if(result.data) {
              setActiveStep(3);   
              setServerErrors([]);
              actions.setSubmitting(false);
            }
            else if(result.errors) {
              setServerErrors(result.errors);
              actions.setSubmitting(false);
            }else if(result.error) {
              setServerErrors([{message:result.error}]);
              actions.setSubmitting(false);
            }
          }   
      }
  }

  function handleSubmit(values, actions) {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  }

  function handleBack() {
    setActiveStep(activeStep - 1);
  } 

  
  const onEdit = (step) => {
    setActiveStep(step);
  }
  
  const formProps = {init:props.init, getChoices, onEdit};

  return (
    <>
        {activeStep === steps.length ? (
          <Success {...formProps}/>
        ) : (
          <React.Fragment>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map(label => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
              ))}
            </Stepper>
            <Formik 
              initialValues={formInitialValues}
              validationSchema={currentValidationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form id={formId}>
                  {renderStepContent(activeStep, formProps)}

                  <div className={classes.buttons}>
                    <div className={classes.wrapper}>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} className={classes.buttonBack} variant="contained" color="default">
                        Back
                      </Button>
                    )}
                    
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        color="secondary"
                        className={classes.buttonNext}
                      >
                        {isLastStep ? 'Submit' : 'Next'}
                      </Button>
                      {isSubmitting && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                  </div>
                  <div className="reCaptcha">
                  { activeStep == 2 && <ReCAPTCHA
                          sitekey={config.RecaptchaKey}
                          ref={recaptchaRef}
                          size="invisible"
                      />
                  }
                </div>
                </Form>
              )}
            </Formik>
            {serverErrors && serverErrors.length > 0 && <Errors errors={serverErrors} />}
        </React.Fragment>
        )}
    </>
  );
}
