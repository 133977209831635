import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useStyles from './styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button';


export default function Header() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
    <AppBar className={classes.appBar}>
      <Toolbar>
          <a href="https://www.berkeleyfoundation.org.uk/" target="_blank" title="visit Berkeley Foundation"><img src="bgfoundation-logo.png" /></a>
      </Toolbar>
    </AppBar>
    <section className={classes.overlayContainer}>
      </section>
    </div>
  );
}
