import * as Yup from 'yup';
import moment from 'moment';
import eligibilityCheckFormModel from './eligibilityCheckFormModel';
const {
  formField: {
    regCharity,
    location,
    workPriorities,
  }
} = eligibilityCheckFormModel;


export default [
  Yup.object().shape({
    [location.name]: Yup.array().min(1, `${location.requiredErrorMsg}`),
    [workPriorities.name]: Yup.array().min(1, `${workPriorities.requiredErrorMsg}`),
  })
];
