import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography ,TextField, MenuItem} from '@material-ui/core';
import { InputField, CheckboxField, SelectField } from '../fields';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import useStyles from './styles';
import { SelectType } from '../common/DataTypes';


export default function PersonalDetails(props) {
  const classes = useStyles();

  const {
    formField: {
      salutation,
      firstName,
      lastName,
      jobTitle,
      email,
      confirmEmail,
      phone,
    },
    getChoices,
  } = props;

  const salutations = getChoices("BGSalutation") && getChoices("BGSalutation");
  const eligibleText = props.init && props.init.formTexts.EligibleText;

  return (
    <React.Fragment>
      <Grid item xs={12}>
      <Card className={classes.info} variant="outlined" color="primary">
        <CardContent>
            <Typography variant="p">
              <div dangerouslySetInnerHTML={{__html:eligibleText}}></div>
            </Typography>
          </CardContent>
      </Card>    
        </Grid>
      <br/>
        <Grid item xs={12}>
          <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary" gutterBottom>
          Personal details
          </Typography>
        </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} className="selectRadio">
          <SelectField
            name={salutation.name}
            label={salutation.label}
            data={salutations}
            fullWidth
            variant="outlined"
            color="secondary"
            selectType={SelectType.RADIO}
          />
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={firstName.name} label={firstName.label} fullWidth variant="outlined" color="secondary"/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={lastName.name} label={lastName.label} fullWidth variant="outlined" color="secondary"/>
        </Grid>
       
        <Grid item xs={12}>
          <InputField name={jobTitle.name} label={jobTitle.label} fullWidth variant="outlined" color="secondary"/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={email.name} label={email.label} type="Email" fullWidth variant="outlined" color="secondary"/>
        </Grid>
         <Grid item xs={12} sm={6}>
          <InputField name={confirmEmail.name} label={confirmEmail.label} type="Email" fullWidth variant="outlined" color="secondary"/>
        </Grid> 
        <Grid item xs={12}>
          <InputField name={phone.name} label={phone.label} type="text" fullWidth variant="outlined" color="secondary"/>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
