import MaterialLayout from './components/layout/MaterialLayout';
import React, {useState, useEffect, useContext} from 'react';
import {EoiForm, EligibilityCheck, Ineligible, Errors}  from './components/forms';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { EligibilityContext } from './components/common/EligibilityContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    loading: {
      textAlign:'center',
    }
   
  }),
);

function App() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = React.useState(0);
  const [init, setInit] = useState();
  const [config, setConfig] = useState();
  const [fieldsData, setFieldsData] = useState();
  const [initErrors, setInitErrors] = useState();
  const [eligibilityResult, setEligibilityResult] = useState({});


  useEffect(() => {
    getConfig()
  }, [])

  const handleEligibility = (result) => {
    setEligibilityResult(result);
  }

  return (
    <div className={classes.root}>
      <EligibilityContext.Provider value={{eligibilityResult, setEligibilityResult}}>
        <MaterialLayout>
          {loading && <CircularProgressWithLabel loading={classes.loading} value={progress} />}
          {!eligibilityResult.checked &&  init && <EligibilityCheck init={init} config={config} handleEligibility = {handleEligibility} /> }
          {eligibilityResult.checked &&  init && !eligibilityResult.passed && <Ineligible init={init}/> }
          {eligibilityResult.checked &&  init && eligibilityResult.passed && <EoiForm init={init}/> }
          {initErrors && initErrors.length > 0 && <Errors errors={initErrors}/> }
        </MaterialLayout>
      </EligibilityContext.Provider>
    </div>
  );

  function getInit(config){
    console.log("Fetching:", config.ApiUrl);
    fetch(
      `${config.ApiUrl}/init`,
        {
          headers: {
              "Authorization":`Bearer ${config.ApiToken}`
          }
      }
    ).then(response=>{
        return response.json()
    }).then(result=>{
      if(result && result.data) {
        setInit(result.data);
        setFieldsData(result.data.fields.value);
        setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 50));
        setLoading(false);
      }else {
        setInitErrors(result.errors);
        setLoading(false);
        console.log(result.errors);
      }
    })
  }

  function getConfig(){
    let apiUrl = process.env.REACT_APP_API_URL? process.env.REACT_APP_API_URL:'/api';
    console.log("Fetching config:", apiUrl);
    fetch(
      `${apiUrl}/config`
    ).then(response=>{
        return response.json()
    }).then(result=>{
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 50));
      if(result && result.data) { 
        setConfig(result.data);
        getInit(result.data);
      }else {
        setInitErrors(result.errors);
        console.log(result.errors);
      }
    })
  }
}

function CircularProgressWithLabel(props) {
  return (
    <div className={props.loading}><Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
    </div>
  );
}

export default App;
