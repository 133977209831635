import { makeStyles } from '@material-ui/core/styles';
export default makeStyles(theme => ({
  root: {
    minWidth: 275,
  },
  info: {
    backgroundColor:'#8aaf45',
    color:'#fff',
    lineHeight:1.66,
    borderColor:'#8b9d3e',
    borderRadius:0
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  title: {
    flexGrow: 1,
    fontFamily:'Rockwell-MT',
    borderBottom:14,
    ':after': {
      content:'test ',
      borderBottom:14,
      width:100
    }
  },
  subTitle: {
    flexGrow: 1,
    fontFamily:'Rockwell-MT',
    borderBottom:14,
    ':after': {
      content:'test ',
      borderBottom:14,
      width:100
    }
  },
  stepper: {
    padding: theme.spacing(3, 0, 5)
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  buttonNext: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    color:'#fff',
    fontFamily: 'Rockwell-MT',
    fontWeight: 'bold',
    color:'#fff',
    letterSpacing:2
  },
  buttonBack: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    fontFamily: 'Rockwell-MT',
    fontWeight: 'bold',
    letterSpacing:2,
    color:'#666',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%'
  },
  notice: {
    backgroundColor:theme.palette.primary.main,
    color:'#fff'
  },
  regcharityText: {
    paddingLeft:10,
    '& b' : {
      color:theme.palette.secondary.main
    }
  },


  review: {
    width: '100%',
    '& a': {
      color:theme.palette.secondary.main,
      textDecoration:'none',
      '&:hover': {
        textDecoration:'underline',
      }
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  subTitle: {
    flexGrow: 1,
    fontFamily:'Rockwell-MT',
    paddingBottom:10,
    borderBottom:14,
    ':after': {
      content:'test ',
      borderBottom:14,
      width:100
    }
  },
  mail: {
    color:theme.palette.secondary.main,
    textDecoration:'none',
    '&:hover': {
      textDecoration:'underline',
    }
  }
}));
